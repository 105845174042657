@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

#polymath {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
}

#pernunsiation {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}
