@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;600;700&family=Roboto+Mono&family=Roboto:wght@300&family=Source+Code+Pro:wght@700&display=swap");
div.boarderh {
  width: 400px;
  height: 60px;
  display: flex;
  padding: 0;
}

div.boarderv {
  width: 400px;
  height: 50px;
  display: flex;
  padding: 0;
}

.parent {
  display: flex;
}

.left,
.right {
  flex: 1;
}

.blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
}

.bigshad {
  filter: drop-shadow(10px 10px 4px #00000042);
}

.title {
  font-family: "Lora", serif;
  color: gold;
  filter: drop-shadow(0px 0px 2px #000000);
  -webkit-text-stroke: 1px rgb(102, 102, 102);
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5),
    -1px -1px 1px rgba(255, 255, 255, 0.9);
  font-size: 30px;
  letter-spacing: 2px;
}

.subtitle {
  font-family: "Lora", serif;
  color: gold;
  filter: drop-shadow(0px 0px 1px #000000);
  -webkit-text-stroke: 1px rgb(53, 49, 24);
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5),
    -1px -1px 0 rgba(255, 255, 255, 0.9);
  font-size: 25px;
  letter-spacing: 1px;
}

.subsubtitle {
  font-family: "Lora", serif;
  color: gold;
  filter: drop-shadow(0px 0px 0px #000000);
  -webkit-text-stroke: 1px rgb(53, 49, 24);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5),
    -1px -1px 0 rgba(255, 255, 255, 0.9);
  font-size: 20px;
  letter-spacing: 2px;
}

div.contentBlock {
  margin-left: 10px;
  margin-right: 10px;
}

div.contentBlock p {
  text-align: left;
}

.centered {
  display: inline;
}

.red {
  background-color: red;
}

.quote1 {
  font-style: italic;
}

.quote2 {
  font-size: 10px;
}
