body {
  margin: 0;
}

.App {
  font-family: sans-serif;
  text-align: center;
  contain: paint;
}

* {
  scroll-margin-top: 100px;
}
