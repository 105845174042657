.blueprint {
  background-color: darkblue;
  display: flex;
  flex-direction: column;
  color: white;
}

.solidol {
  outline-style: solid;
  width: 100%;
  outline-width: 1px;
  outline-color: rgba(255, 255, 255, 0.5);
}

.dotol {
  outline-style: dotted;
  width: 100%;
  outline-width: 1px;
  outline-color: rgba(255, 255, 255, 0.5);
}

#roboarm1 {
  position: absolute;
  outline-style: solid;
  outline-width: 5px;
  margin-left: 20px;
  margin-top: 100px;
  width: 40px;
  height: 10px;
  transform-origin: 0% 050%;
  rotate: -20deg;
  outline-color: white;
  background-color: darkblue;
  animation: move1 20s infinite ease-in-out;
}

#roboarm2 {
  position: absolute;
  outline-style: solid;
  outline-width: 5px;
  margin-left: 35px;
  margin-top: 0px;
  width: 50px;
  height: 10px;
  transform-origin: 0% 50%;
  rotate: -140deg;
  outline-color: white;
  background-color: darkblue;
  animation: move2 20s infinite ease-in-out;
}

#roboarm3 {
  position: absolute;
  outline-style: solid;
  outline-width: 5px;
  margin-left: 60px;
  margin-top: 0px;
  width: 50px;
  height: 10px;
  transform-origin: 0% 50%;
  rotate: 140deg;
  outline-color: white;
  background-color: darkblue;
  animation: move3 20s infinite ease-in-out;
}

#roboarm4 {
  position: absolute;
  outline-style: solid;
  outline-width: 2px;
  margin-left: 50px;
  margin-top: -2px;
  width: 30px;
  height: 1px;
  transform-origin: 0% 50%;
  rotate: 0deg;
  outline-color: white;
  background-color: darkblue;
  animation: move4 20s infinite ease-in-out;
}

#roboarm5 {
  position: absolute;
  outline-style: solid;
  outline-width: 2px;
  margin-left: 50px;
  margin-top: -2px;
  width: 30px;
  height: 1px;
  transform-origin: 0% 50%;
  rotate: 0deg;
  outline-color: white;
  background-color: darkblue;
  animation: move5 20s infinite ease-in-out;
}

@keyframes move1 {
  0%,
  30%,
  65%,
  100% {
    transform: rotate(-20deg);
  }
  35%,
  60% {
    transform: rotate(-10deg);
  }
}

@keyframes move2 {
  0%,
  30%,
  65%,
  80%,
  100% {
    transform: rotate(50deg);
  }
  35%,
  60%,
  85%,
  95% {
    transform: rotate(20deg);
  }
}

@keyframes move3 {
  0%,
  30%,
  65%,
  100% {
    transform: rotate(-10deg);
  }
  35%,
  60% {
    transform: rotate(0deg);
  }
}

@keyframes move4 {
  0%,
  30%,
  65%,
  80%,
  100% {
    transform: rotate(90deg);
  }
  35%,
  60% {
    transform: rotate(50deg);
  }
  85%,
  95% {
    transform: rotate(40deg);
  }
}

@keyframes move5 {
  0%,
  30%,
  65%,
  80%,
  100% {
    transform: rotate(0deg);
  }
  35%,
  60% {
    transform: rotate(-30deg);
  }
  85%,
  95% {
    transform: rotate(0deg);
  }
}

img.exampleImg {
  border-radius: 20px;
  max-width: 100%;
  max-height: 100px;
}
