.sendInquiry input {
  font-size: "15px";
}

body {
  background-color: rgb(255, 255, 140);
}

.hireView h2 {
  color: black;
}

.hireView h1 {
  color: black;
}
