:root {
  --bar-width: 30px;
  --bar-height: 4px;
  --hamburger-gap: 6px;
  --foreground: #333;
  --background: white;
  --hamburger-margin: 8px;
  --animation-timing: 200ms ease-in-out;
  --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
}

.hamburger-menu {
  --x-width: calc(var(--hamburger-height) * 1.41421356237);
  translate: -15px;
  display: flex;
  flex-direction: column;
  gap: var(--hamburger-gap);
  width: max-content;
  top: var(--hamburger-margin);
  left: var(--hamburger-margin);
  z-index: 2;
  cursor: pointer;
}

.hamburger-menu:has(input:checked) {
  --foreground: white;
  --background: #333;
}

.hamburger-menu:has(input:focus-visible)::before,
.hamburger-menu:has(input:focus-visible)::after,
.hamburger-menu input:focus-visible {
  border: 1px solid var(--background);
  box-shadow: 0 0 0 1px var(--foreground);
}

.hamburger-menu::before,
.hamburger-menu::after,
.hamburger-menu input {
  content: "";
  width: var(--bar-width);
  height: var(--bar-height);
  background-color: var(--foreground);
  border-radius: 9999px;
  transform-origin: left center;
  transition: opacity var(--animation-timing), width var(--animation-timing),
    rotate var(--animation-timing), translate var(--animation-timing),
    background-color var(--animation-timing);
}

.hamburger-menu input {
  appearance: none;
  padding: 0;
  margin: 0;
  outline: none;
  pointer-events: none;
}

.hamburger-menu:has(input:checked)::before {
  rotate: 45deg;
  width: var(--x-width);
  translate: 0 calc(var(--bar-height) / -2);
}

.hamburger-menu:has(input:checked)::after {
  rotate: -45deg;
  width: var(--x-width);
  translate: 0 calc(var(--bar-height) / 2);
}

.hamburger-menu input:checked {
  opacity: 0;
  width: 0;
}

.sidebar {
  position: absolute;
  top: 0;
  transition: translate var(--animation-timing);
  translate: 40px;
  padding: 5px;
  padding-top: 80px;
  padding-bottom: 80px;
  border-bottom-left-radius: 100px;
  background-color: var(--foreground);
  color: var(--background);
  width: 100px;
}

.hamburger-menu:has(input:checked) + .sidebar {
  translate: -60px;
}

.navElem {
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
}
