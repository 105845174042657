div.artistContainer img {
  object-fit: none;
  scale: "0.1";
  object-position: center center;
}

.imgContain {
  display: flex;
}

img {
  max-width: 100%;
}

.craftContent h1 {
  -webkit-text-stroke: 2px #000000;
  text-shadow: 0px 0px 20px #ffffff;
  color: forestgreen;
  margin-top: 1px;
  margin-bottom: 1px;
}
